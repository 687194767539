import { render, staticRenderFns } from "./ChoosePlan.vue?vue&type=template&id=2ff058b7&scoped=true&lang=true"
import script from "./ChoosePlan.vue?vue&type=script&lang=js"
export * from "./ChoosePlan.vue?vue&type=script&lang=js"
import style0 from "./ChoosePlan.vue?vue&type=style&index=0&id=2ff058b7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff058b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignInSignUp: require('/codebuild/output/src687347117/src/client/components/SignInSignUp.vue').default,CountryFlag: require('/codebuild/output/src687347117/src/client/components/CountryFlag.vue').default,PricingCard: require('/codebuild/output/src687347117/src/client/components/PricingCard.vue').default})
